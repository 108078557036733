(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-account-balance/assets/account-balance.js') >= 0) return;  svs.modules.push('/components/payment/middleware-account-balance/assets/account-balance.js');
'use strict';

const {
  account_balance: accountBalanceComponent,
  analytics
} = svs.components;
const {
  Currency: currency,
  CurrencyFromJupiterString: currencyFromJupiterString
} = svs.utils.format;
const {
  InteractionContext
} = svs.components.payment.common.context;
const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const {
  common
} = svs.components.payment;
const {
  isWager
} = svs.components.payment.createWager.utils;
const {
  form,
  products
} = svs.utils;
const {
  urlMapping
} = svs.core;
const getAccountBalance = () => new Promise(resolve => {
  accountBalanceComponent.get(true, balance => {
    resolve(currencyFromJupiterString(balance));
  });
});
const trackDepositAction = productId => {
  analytics.trackEvent({
    category: 'overlays',
    action: 'deposit',
    opt_label: products.getDisplayName(productId)
  });
};
const accountBalance = onInteraction => _ref => {
  let {
    payment,
    userContext = {}
  } = _ref;
  return async next => {
    const balance = await getAccountBalance();
    const price = payment.getTotalPrice();
    common.logger.info("Checking account balance");
    if (price > balance) {
      common.logger.info("Account balance too low: ".concat(balance, ", price: ").concat(price));
      const wagerCount = payment.count() > 1 ? 'dina' : 'ditt';
      const title = "Du har inte tillr\xE4ckligt med pengar p\xE5 ditt spelkonto f\xF6r att betala ".concat(wagerCount, " spel");
      const messages = ["Saldo: ".concat(currency(balance), " kr"), "Pris: ".concat(currency(price), " kr")];
      const data = {
        balance,
        price,
        wagerCount: payment.count()
      };
      try {
        common.logger.info('Not enough money, showing dialog to deposit money.');
        const result = await onInteraction(new InteractionContext(InteractionContext.Severity.ERROR, InteractionContext.Code.NOT_ENOUGH_MONEY, data, title, messages));
        const [firstPayable] = payment.getPayableItems();
        if (isWager(firstPayable)) {
          const productId = firstPayable.bets[0].productId;
          trackDepositAction(productId);
        }

        const returnUrl = common.getReturnUrl(window.location, result);
        const {
          returnQuery
        } = userContext;
        if (returnQuery) {
          const entries = returnQuery instanceof URLSearchParams ? returnQuery.entries() : Object.entries(returnQuery);
          for (const [key, value] of entries) {
            returnUrl.searchParams.append(key, value);
          }
        }
        form.postToUrl(urlMapping.get('transactionsHome'), {
          returnUrl
        });
      } catch (err) {
        common.logger.debug(err === null || err === void 0 ? void 0 : err.message, err);
      }
      const mwError = new MiddlewarePaymentError({
        clientCode: MiddlewarePaymentError.ErrorCodes.NOT_ENOUGH_MONEY,
        extraData: {
          balance,
          price
        }
      });
      return next(mwError);
    }
    common.logger.debug("Account balance check successful");
    next();
  };
};
setGlobal('svs.components.payment.middleware.accountBalance', accountBalance);

 })(window);